@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: "Lexend", sans-serif;
  font-weight: 400;
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
}


.animation {
  transition: all 1s ease-in-out;

}

h1 {
  line-height: 85px;
}


.navButton {
  padding: 10px 28px;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
  background-color: #27D690;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
  font-weight: 500;
}

.navButton:hover {
  letter-spacing: 3px;
  background-color: #070324;
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(46, 13, 107) 0px 7px 29px 0px;
}

.navButton:active {
  letter-spacing: 3px;
  background-color: hsl(261deg 80% 48%);
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(93 24 220) 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
}




/* .button2 {
  padding: 17px 40px;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
  color: black;
}

.button2:hover {
  letter-spacing: 3px;
  background-color: #070324;
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(45, 13, 105) 0px 7px 29px 0px;
}

.button2:active {
  letter-spacing: 3px;
  background-color: #321070;
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(44, 12, 104) 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
} */



.swiper {
  width: 100%;
  height: 100%;
  padding: 140px 0px 0px 0px !important;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: 100% !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev::after {
  font-size: 20px !important;
  color: #000;
  content: "\2190" !important;
}

.swiper-button-next::after {
  font-size: 20px !important;
  color: #000;
  content: "\2192" !important;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #fff !important;
  border: 1px solid #070324 !important;
  padding: 12px !important;
  font-size: 12px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.swiper-button-prev {
  position: absolute !important;
  top: 9% !important;
  left: 46% !important;
}

.swiper-button-next {
  position: absolute !important;
  top: 9% !important;
  right: 46% !important;
}




.bottomTop {
  animation: moveUp 2s ease-in-out infinite alternate;
  /* Adjust animation duration and timing function as needed */
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.rotate {
  animation: moveRotate 2s ease-in-out infinite alternate;
}

@keyframes moveRotate {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  100% {
    transform: translateY(-100%) rotate(360deg);
  }
}


@media only screen and (min-width: 480px) and (max-width:768px) {
  body {
    overflow-x: hidden;
  }

  .swiper-button-prev {
    position: absolute !important;
    top: 9% !important;
    left: 43% !important;
  }

  .swiper-button-next {
    position: absolute !important;
    top: 9% !important;
    right: 43% !important;
  }

  .slider {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 480px) {
  .swiper-button-prev {
    position: absolute !important;
    top: 9% !important;
    left: 35% !important;
  }

  .swiper-button-next {
    position: absolute !important;
    top: 9% !important;
    right: 35% !important;
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding: 100px 0px 0px 0px !important;
  }


  .slider {
    overflow-x: hidden;
    position: relative;
    padding: 105px 0 0 0;
    transform: translateY(-105px);
  }
}